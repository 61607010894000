import React from 'react';
import './FillCategories.css';

type FillCategoriesProps = { categories: string[], letter: string, sessionId: string, answers: string[], setAnswers: Function, timeIsUp: boolean }
const FillCategories = (props: FillCategoriesProps) => {

    function updateInputValue(category: string, event: React.ChangeEvent<HTMLInputElement>) {
        props.answers[props.categories.indexOf(category)] = event.target.value;
        props.setAnswers(props.answers);
    }

    return <div id="fill-categories">
        {props.categories.map(category => {
            return <div key={category} className="input-group"><label
                htmlFor={`input-${category}`}>{category}</label><input
                id={`input-${category}`} placeholder={`${props.letter}...`} maxLength={200}
                autoComplete="off" onChange={evt => updateInputValue(category, evt)} disabled={props.timeIsUp}/></div>
        })}
    </div>;
}

export default FillCategories;
