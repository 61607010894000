import React, {useState} from 'react';
import './VetoButton.css';
import axios from "axios";

type VetoButtonProps = { sessionId: string, answerId: string, pollFunc: Function }
const VetoButton = (props: VetoButtonProps) => {

    const [vetoSent, setVetoSent] = useState(false);

    function sendVeto() {
        axios(`${process.env.REACT_APP_BACKEND_URL}/game/veto`,
            {
                method: 'post',
                data: {
                    session: props.sessionId,
                    answerId: props.answerId
                }
            }
        ).then(() => {
            setVetoSent(!vetoSent);
            props.pollFunc();
        })
    }

    return <button className={`veto-button ${vetoSent ? "veto-pressed" : ""}`} onClick={sendVeto}>Veto</button>;
}

export default VetoButton;
