import React from 'react';
import './RoundIndicator.css';
import {useTranslation} from "react-i18next";

type RoundIndicatorProps = { current: number, total: number }
const RoundIndicator = (props: RoundIndicatorProps) => {
    const {t} = useTranslation();

    return <div id="round-indicator">{t('roundIndicator.round')} {props.current} / {props.total}</div>
};

export default RoundIndicator;
