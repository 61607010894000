import React from 'react';
import logo from './stadt-land-flusspferd.png';
import logoDark from './stadt-land-flusspferd_dark.png';
import logoMobile from './stadt-land-flusspferd_mobile.png';
import logoMobileDark from './stadt-land-flusspferd_mobile_dark.png';
import enLogo from './categories-online.png';
import enLogoDark from './categories-online_dark.png';
import enLogoMobile from './categories-online_mobile.png';
import enLogoMobileDark from './categories-online_mobile_dark.png';
import './Logo.css';
import {useTranslation} from "react-i18next";
import i18n from "i18next";

type LogoProps = { isDarkMode: boolean }
const Logo = (props: LogoProps) => {

    const {t} = useTranslation();

    let desktopLogo;
    let mobileLogo;
    if (i18n.language === 'de') {
        if (props.isDarkMode) {
            desktopLogo = logoDark;
            mobileLogo = logoMobileDark;
        } else {
            desktopLogo = logo;
            mobileLogo = logoMobile
        }
    } else {
        if (props.isDarkMode) {
            desktopLogo = enLogoDark;
            mobileLogo = enLogoMobileDark;
        } else {
            desktopLogo = enLogo;
            mobileLogo = enLogoMobile
        }
    }

    return (<div>
        <img src={desktopLogo} className="logo-desktop"
             alt={t('common.logo-altText')}/>
        <img src={mobileLogo} className="logo-mobile"
             alt={t('common.logo-altText')}/>
    </div>);
}

export default Logo;
