import React from 'react';
import './ValidateCategories.css';
import {AnswerStatus, CategoryAnswer, Player} from "../../entity/GameState";
import VetoButton from "./veto-button/VetoButton";
import LikeButton from "./like-button/LikeButton";
import ReactTooltip from "react-tooltip";
import {useTranslation} from "react-i18next";

type ValidateCategoriesProps = { answers: CategoryAnswer[], sessionId: string, userFinished: boolean, allCreativityPointsSpent: boolean, creativityPointValue: number, pointSpent: Function, pointRevoked: Function, ownFrontendId: string, players: Player[], pollFunc: Function }
const ValidateCategories = (props: ValidateCategoriesProps) => {
    const {t} = useTranslation();

    return <div id="validate-categories">
        {props.answers.map(answer => {
            return <div key={answer.category}>
                <div className="category">{answer.category}</div>
                <div className="result-table">
                    <div className="divTableBody">
                        {answer.userAnswers.map(ua => {
                            return <div key={ua.answerId} className="divTableRow">
                                <div className="divTableCell result-table-player">{ua.player.name}</div>
                                <div className="divTableCell answer">
                                    {ua.answer ? ua.answer : '-------'} {!props.userFinished && ua.answer && ua.status !== AnswerStatus.RED &&
                                <VetoButton answerId={ua.answerId} sessionId={props.sessionId}
                                            pollFunc={props.pollFunc}/>}
                                    {!props.userFinished && ua.answer && props.creativityPointValue > 0 && ua.player.frontendId !== props.ownFrontendId &&
                                    <LikeButton answerId={ua.answerId} sessionId={props.sessionId}
                                                pointValue={props.creativityPointValue}
                                                pointSpent={props.pointSpent}
                                                pointRevoked={props.pointRevoked}
                                                disabled={props.allCreativityPointsSpent}/>}
                                </div>
                                <div className="divTableCell result-status">
                                    <div className={ua.status.toString()}>&nbsp;</div>
                                </div>
                                <div className="divTableCell">
                                    {ua.points} {ua.creativityPlayerFrontendIds.length > 0 ?
                                    <span>
                                        <a className="received-creativity-points" data-tip
                                           data-for="received-creativity-points-tooltip">
                                            +{ua.creativityPlayerFrontendIds.length * props.creativityPointValue}
                                        </a>
                                        <ReactTooltip id="received-creativity-points-tooltip" place="top" type="dark"
                                                      effect="float">{t('page.validateCategories.creativityPointsReceivedFrom')}: {ua.creativityPlayerFrontendIds.map(id => props.players.find(player => player.frontendId === id)?.name).join(', ')}</ReactTooltip></span>
                                    : ""}
                                </div>
                            </div>
                        })}
                    </div>
                </div>
            </div>;
        })}
    </div>;
};

export default ValidateCategories;
