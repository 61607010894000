export const setCookie = (sessionId: string) => {
    document.cookie = `hippo=${sessionId};SameSite=Strict;path=/;secure=true;`;
}

export const getSessionIdFromCookie = () => {
    const cookies = document.cookie;
    const cookiePattern = /hippo=[a-z0-9-]{36}/i;
    const cookiePos = cookies.search(cookiePattern);
    const idLength = 36;
    return cookies.substr(cookiePos + 6, idLength);
}
