import React, {useState} from 'react';
import '../common/Game.css';
import axios from "axios";
import Letter from "../letter/Letter";
import PlayerList from "../player-list/PlayerList";
import FillCategories from "./FillCategories";
import {GameState} from "../../entity/GameState";
import Clock from "../clock/Clock";
import RoundIndicator from "../round-indicator/RoundIndicator";

type FillCategoriesPageProps = { callback: Function, timeOffset: number, sessionId: string, gameState: GameState, syncTimeFunc: Function }
const FillCategoriesPage = (props: FillCategoriesPageProps) => {

    const [answers, setAnswers] = useState<string[]>(props.gameState.game.categories.map(e => ''));
    const [timeIsUp, setTimeIsUp] = useState<boolean>(false);

    const sendClientInput = () => {
        setTimeIsUp(true);
        axios(`${process.env.REACT_APP_BACKEND_URL}/game/answers`,
            {
                method: 'post',
                data: {
                    session: props.sessionId,
                    values: answers
                }
            }
        ).then(() => {
            props.callback();
        }).catch(response => {
            // TODO check if status code comes here
            if (response.status === 425) {
                setTimeout(function () {
                    sendClientInput();
                }, 1000);
            } else {
                props.syncTimeFunc();
            }
        });
    };


    return <div id="game">
        <div id="play">
            <FillCategories categories={props.gameState.game.categories} letter={props.gameState.extra.letter}
                            sessionId={props.sessionId} answers={answers} setAnswers={setAnswers} timeIsUp={timeIsUp}/>
        </div>
        <div id="info-bar">
            <div id="info-wrapper">
                <Letter current={props.gameState.extra.letter}/>
                <Clock nextPoll={props.gameState.nextPoll}
                       callback={sendClientInput}
                       visibleTimer={true}/>
                <PlayerList players={props.gameState.players} readyPlayers={props.gameState.extra.readyPlayers}
                            currentStep={props.gameState.step} timeOffset={props.timeOffset}
                            sessionId={props.sessionId}/>
                <RoundIndicator current={props.gameState.game.currentRound} total={props.gameState.game.rounds}/>
            </div>
        </div>
    </div>;
}

export default FillCategoriesPage;
