import React from 'react';
import './SmartPhrase.css';
import {useTranslation} from "react-i18next";

const SmartPhrase = () => {

    const {t} = useTranslation();

    const randomPhrase = () => {
        const items = [
            t('page.start.phrase1'),
            t('page.start.phrase2'),
            t('page.start.phrase3'),
            t('page.start.phrase4')
        ];
        return items[Math.floor(Math.random() * items.length)];
    };

    return <p className="smart-phrase">{randomPhrase()}</p>
};

export default SmartPhrase;
