import React from 'react';
import '../common/Game.css';
import Start from "./Start";
import PlayerList from "../player-list/PlayerList";
import {GameState} from "../../entity/GameState";
import Clock from "../clock/Clock";
import RoundIndicator from "../round-indicator/RoundIndicator";

type StartPageProps = { callback: Function, timeOffset: number, sessionId: string, gameState: GameState }
const StartPage = (props: StartPageProps) => {

    return <div id="game">
        <div id="play">
            <Start gameState={props.gameState}/>
        </div>
        <div id="info-bar">
            <div id="info-wrapper">
                <Clock nextPoll={props.gameState.nextPoll}
                       callback={props.callback}
                       visibleTimer={true}/>
                <PlayerList players={props.gameState.players} readyPlayers={props.gameState.extra.readyPlayers}
                            currentStep={props.gameState.step} timeOffset={props.timeOffset}
                            sessionId={props.sessionId}/>
                <RoundIndicator current={props.gameState.game.currentRound} total={props.gameState.game.rounds}/>
            </div>
        </div>
    </div>;
}

export default StartPage;
