import React, {useState} from 'react';
import './Category-Input.css';
import dice from './icon_dice.png';
import RandomCategoryService from "./RandomCategoryService";
import {useTranslation} from "react-i18next";
import i18n from "i18next";

interface CategoryInputProps {
    index: number
    initCategory: string;
    randomCategoryService: RandomCategoryService;
    setCategoryCallback: Function;
}

const CategoryInput = (props: CategoryInputProps) => {

    const {t} = useTranslation();
    const [category, setCategory] = useState(props.initCategory);

    const randomCategory = () => {
        props.randomCategoryService.getRandom(i18n.language)
            .then(c => {
                const someKindOfEvent = {
                    target: {
                        id: props.index,
                        value: c
                    }
                }
                props.setCategoryCallback(someKindOfEvent);
                setCategory(c);
            });
    }

    function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
        props.setCategoryCallback(e);
        setCategory(e.target.value);
    }

    return <main>
        <input type="text" id={props.index.toString()} value={category} onChange={e => handleChange(e)}/>
        <img alt={t('page.configureGame.diceAltText')} className="icon random-category"
             onClick={() => randomCategory()}
             src={dice}/>
    </main>;
}

export default CategoryInput;
