import React, {useState} from "react";
import "./PlayerList.css";
import {Player, Step} from "../../entity/GameState";
import {calculateDistanceInSecs} from "../../utils/Calculator";
import axios from "axios";
import connectionIssueIcon from "./icon_lightning.png";
import readyPlayerIcon from "./icon_check.png";
import {useTranslation} from "react-i18next";

type PlayerListProps = { players: Player[], readyPlayers: string[], currentStep: Step, timeOffset: number, sessionId: string }
const PlayerList = (props: PlayerListProps) => {

    const {t} = useTranslation();
    const [executedKick, setExecutedKick] = useState<boolean>(false);

    const canKick = () => props.currentStep === Step.LOBBY || props.currentStep === Step.VALIDATE_CATEGORIES;

    const isActive = (player: Player) => calculateDistanceInSecs(player.lastPoll) - (props.timeOffset / 1000) > -30

    const kickInactivePlayers = () => {
        axios.put(`${process.env.REACT_APP_BACKEND_URL}/player/kick-inactive`,
            {session: props.sessionId})
            .then(() => {
                setExecutedKick(true);
            });
    }

    const kickPlayerButton = <span><img className="icon" src={connectionIssueIcon}
                                        alt={t('playerList.connectionIssue-altText')}/> <span
        className="kick-button button-link"
        onClick={kickInactivePlayers}>{t('playerList.connectionIssue-kickButton')}</span></span>;


    return <div id="players">
        {props.players.map(p => {
            return <div key={p.frontendId} className="player">
                {p.name}
                {(props.readyPlayers && props.readyPlayers.indexOf(p.frontendId) > -1) ?
                    <img className="icon" src={readyPlayerIcon} alt={t('playerList.readyPlayer-altText')}/> : ""}
                {!isActive(p) && canKick() && !executedKick && kickPlayerButton}
            </div>
        })}
    </div>;
};

export default PlayerList;
