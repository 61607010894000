import React from 'react';
import './Start.css';
import {GameState, PlayerScore} from "../../entity/GameState";
import SmartPhrase from "./smart-phrase/SmartPhrase";
import {useTranslation} from "react-i18next";

type StartProps = { gameState: GameState }

const Start = (props: StartProps) => {

    const {t} = useTranslation();

    function currentRound(roundPoints: number[]) {
        return roundPoints[roundPoints.length - 1];
    }

    return <div id="start">
        {props.gameState.game.currentRound === 1 ? <SmartPhrase/> : <div id="score">
            <h2>{t('page.start.intermediateResult')}</h2>
            {props.gameState.extra.playerScores.map((ps: PlayerScore) => {
                return <p>{ps.player.name}: {ps.roundPoints.reduce((pv, cv) => pv + cv, 0)}{currentRound(ps.roundPoints) ?
                    <span className="new-points"> (+{currentRound(ps.roundPoints)})</span> : ""}</p>
            })}
        </div>}
    </div>;
}

export default Start;
