import React from 'react';
import './Letter.css';

type LetterProps = { current: string }
const Letter = (props: LetterProps) => {
    // key attribute forces react to rerender the whole div on props change. This causes the animation to be replayed.
    return <div id="letter" key={props.current}>{props.current}</div>
};

export default Letter;
