import React, {useEffect} from 'react';
import '../Charts.css';
import {CategoryStat} from "../../../entity/GameState";
import c3 from "c3";
import "c3/c3.css";
import {useTranslation} from "react-i18next";

type CategoryBarChartProps = { categories: string[], categoryStats: CategoryStat }
const CategoryBarChart = (props: CategoryBarChartProps) => {

    const {t} = useTranslation();

    useEffect(() => {
        const data: any = [];
        for (const [key, value] of Object.entries(props.categoryStats)) {
            value.unshift(key);
            data.push(value);
        }

        c3.generate({
            bindto: '#category-bar-chart-chart',
            size: {
                height: props.categories.length * data.length * 35
            },
            data: {
                columns: data as any,
                type: 'bar'
            },
            bar: {
                width: 18
            },
            axis: {
                rotated: true,
                x: {
                    type: 'category',
                    categories: props.categories
                }
            }
        });
    }, []);

    return <div id="category-bar-chart">
        <h3>{t('page.end.categoryBarChart-title')}</h3>
        <div id="category-bar-chart-chart" className="asdf-chart"/>
    </div>
}

export default CategoryBarChart;
