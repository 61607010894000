import i18n from 'i18next';
import {initReactI18next} from "react-i18next";

import enFile from './en.json';
import deFile from './de.json';

i18n
    .use(initReactI18next)
    .init({
        fallbackLng: process.env.REACT_APP_LANGUAGE,
        interpolation: {
            escapeValue: false,
        },
        resources: {
            en: enFile,
            de: deFile,
        },
    });

export default i18n;