import React, {useState} from 'react';
import './LikeButton.css';
import axios from "axios";

type LikeButtonProps = { sessionId: string, answerId: string, pointValue: number, pointSpent: Function, pointRevoked: Function, disabled: boolean }
const LikeButton = (props: LikeButtonProps) => {

    const [likeSent, setLikeSent] = useState(false);

    function sendLike() {
        axios(`${process.env.REACT_APP_BACKEND_URL}/game/creativity-point`,
            {
                method: 'post',
                data: {
                    session: props.sessionId,
                    answerId: props.answerId
                }
            }
        ).then(() => {
            if (likeSent) {
                props.pointRevoked();
            } else {
                props.pointSpent();
            }
            setLikeSent(!likeSent);
        }).catch(e => console.error(e));
    }

    return <button onClick={sendLike} className={likeSent ? "like-pressed" : ""}
                   disabled={props.disabled && !likeSent}>+{props.pointValue}</button>;
}

export default LikeButton;
