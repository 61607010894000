import React from 'react';
import './End.css';
import {CategoryStat, PlayerScore, PlayerStats, PointCategories} from "../../entity/GameState";
import PlayerScoreTable from "./player-score-table/PlayerScoreTable";
import RankingChart from "./ranking-chart/RankingChart";
import CategoryBarChart from "./category-bar-chart/CategoryBarChart";
import StatisticsTable from "./statistics/StatisticsTable";
import Ranking from "./ranking/Ranking";
import PointCategoryChart from "./point-category-chart/PointCategoryChart";

type EndProps = { categories: string[], playerNames: string[], endExtra: { playerScores: PlayerScore[], categoryStats: CategoryStat, stats: PlayerStats[], pointCategories: PointCategories } }
const End = (props: EndProps) => {

    return <div id="end">
        <Ranking playerScores={props.endExtra.playerScores}/>
        <PlayerScoreTable playerScores={props.endExtra.playerScores}/>
        <RankingChart playerScores={props.endExtra.playerScores}/>
        <PointCategoryChart playerNames={props.playerNames} pointCategories={props.endExtra.pointCategories}/>
        <CategoryBarChart categories={props.categories} categoryStats={props.endExtra.categoryStats}/>
        <StatisticsTable stats={props.endExtra.stats}/>
    </div>;
}

export default End;
