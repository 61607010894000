import React from 'react';
import i18n from "i18next";
import germanIcon from './german.png';
import englishIcon from './english.png';
import {useLocation} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {getSessionIdFromCookie} from "../../utils/CookieUtils";

const LanguageSwitch = () => {

    const {t} = useTranslation();
    const location = useLocation();

    const getPathForLang = (language: string) => {
        const currentPath = location.pathname.split('/');

        switch (currentPath[1]) {
            case t('navigation.newGame-path'):
                return `/${i18n.getResource(language, 'translation', 'navigation.newGame-path')}`
            case t('navigation.join-path'):
                return `/${i18n.getResource(language, 'translation', 'navigation.join-path')}/${currentPath[2]}`
            case t('navigation.play-path'):
                return `/transfer/${getSessionIdFromCookie()}`
            default:
                return '';
        }
    }

    return <div id="language-switch">
        <a href={`${process.env.REACT_APP_FRONTEND_URL_EN}${getPathForLang('en')}`}>
            <img className="icon clickable" src={englishIcon} alt="Switch to English version"/>
        </a>
        <a href={`${process.env.REACT_APP_FRONTEND_URL_DE}${getPathForLang('de')}`}>
            <img className="icon clickable" src={germanIcon} alt="Zur deutschsprachigen Version wechseln"/>
        </a>
    </div>;
}

export default LanguageSwitch;
