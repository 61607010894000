import React, {useEffect} from 'react';
import '../Charts.css';
import {PointCategories} from "../../../entity/GameState";
import c3 from "c3";
import "c3/c3.css";
import {useTranslation} from "react-i18next";

type PointCategoryChartProps = { playerNames: string[], pointCategories: PointCategories }
const PointCategoryChart = (props: PointCategoryChartProps) => {

    const {t} = useTranslation();

    useEffect(() => {
        const data: any = [];
        for (const [key, value] of Object.entries(props.pointCategories)) {
            value.unshift(key);
            data.push(value);
        }

        c3.generate({
            bindto: '#point-category-chart-chart',
            data: {
                columns: data as any,
                type: 'bar',
                groups: [
                    ['5', '10', '20', 'creativity']
                ]
            },
            axis: {
                x: {
                    type: 'category',
                    categories: props.playerNames
                }
            }
        });
    }, []);

    return <div id="point-category-chart">
        <h3>{t('page.end.pointCategoryChart-title')}</h3>
        <div id="point-category-chart-chart" className="asdf-chart"/>
    </div>
}

export default PointCategoryChart;
