import React, {useEffect} from 'react';
import './PlayerScoreTable.css';
import {PlayerScore} from "../../../entity/GameState";
import {useTranslation} from "react-i18next";

type PlayerScoreProps = { playerScores: PlayerScore[] }
const PlayerScoreTable = (props: PlayerScoreProps) => {

    const {t} = useTranslation();
    let sortedScores: any[] = [];

    useEffect(() => {
        sortedScores = props.playerScores
            .map(s => {
                return {player: s.player.name, score: s.roundPoints.reduce((pv, cv) => pv + cv, 0)}
            }).sort(compareScore);

    }, []);

    function compareScore(a: any, b: any) {
        if (a.score > b.score) {
            return -1;
        } else if (a.score < b.score) {
            return 1;
        }
        return 0;
    }

    let previousPoints = 0;
    let position = 0;

    const totalRoundPoints = [];
    const totalRounds = props.playerScores[0].roundPoints.length;
    for (let i = 0; i < totalRounds; i++) {
        const roundPoints: any = [];
        roundPoints.push(<td>{i + 1}</td>);
        props.playerScores.forEach(s => {
            roundPoints.push(<td>{s.roundPoints[i] ? s.roundPoints[i] : 0}</td>);
        });
        totalRoundPoints.push(<tr>{roundPoints}</tr>)
    }

    return <div id="player-score">
        {sortedScores.length > 0 && sortedScores.map(s => {
            position = previousPoints === s.score ? position : ++position;
            const p = <p key={position} className={`ranking-pos${position}`}>${position}. Platz: ${s.player} -
                ${s.score} Punkte</p>;
            previousPoints = s.score;
            return p
        })}
        <br/>
        <table className="ranking-table">
            <thead>
            <tr>
                <td>{t('page.end.playerScoreTable-round')}</td>
                {props.playerScores.map(s => {
                    return <td key={s.player.frontendId}>{s.player.name}</td>
                })}
            </tr>
            </thead>
            <tbody>
            {totalRoundPoints}
            </tbody>
        </table>
    </div>;
}

export default PlayerScoreTable;
