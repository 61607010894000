import axios from "axios";
import {GameConfig} from "./GameConfig";

class CreateGame {
    create = (config: GameConfig) => {
        return axios(`${process.env.REACT_APP_BACKEND_URL}/game`,
            {
                method: 'post',
                data: config
            }
        ).then(response => {
            return response.data;
        });
    };
}

export default CreateGame;
