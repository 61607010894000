import React from 'react';
import './CreativityPointCounter.css';
import {useTranslation} from "react-i18next";

type CreativityPointCounterProps = { spent: number, total: number }
const CreativityPointCounter = (props: CreativityPointCounterProps) => {
    const {t} = useTranslation();

    return <div
        id="creativity-point-counter">{t('page.validateCategories.remainingCreativityPoints')}: {props.total - props.spent} / {props.total}</div>
};

export default CreativityPointCounter;
