import React from 'react';
import '../Charts.css';
import './Ranking.css';
import {PlayerScore} from "../../../entity/GameState";
import "c3/c3.css";
import {useTranslation} from "react-i18next";

type RankingProps = { playerScores: PlayerScore[] }
const Ranking = (props: RankingProps) => {

    const {t} = useTranslation();

    const sortedScores = props.playerScores
        .map(s => {
            return {player: s.player.name, score: s.roundPoints.reduce((pv, cv) => pv + cv, 0)}
        }).sort(compareScore);

    let previousPoints = 0;
    let position = 0;

    function compareScore(a: any, b: any) {
        if (a.score > b.score) {
            return -1;
        } else if (a.score < b.score) {
            return 1;
        }
        return 0;
    }

    return <div id="ranking">
        {sortedScores.map(s => {
            position = previousPoints === s.score ? position : ++position;
            previousPoints = s.score;
            return <p key={position} className={`ranking-pos${position}`}>{position}.
                {t('page.end.ranking-place')}: {s.player} - {s.score} {t('page.end.ranking-points')}</p>
        })}
    </div>;
}

export default Ranking;
