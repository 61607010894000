import React, {useEffect} from 'react';
import '../Charts.css';
import {PlayerScore} from "../../../entity/GameState";
import c3 from "c3";
import "c3/c3.css";
import {useTranslation} from "react-i18next";

type RankingChartProps = { playerScores: PlayerScore[] }
const RankingChart = (props: RankingChartProps) => {

    const {t} = useTranslation();

    useEffect(() => {
        const data = props.playerScores.map(p => {
            let points = [0].concat(p.roundPoints);
            let sum: number;
            points = points.map(elem => sum = (sum || 0) + elem);
            return [p.player.name, points].flat(1);
        });

        c3.generate({
            bindto: '#ranking-chart-chart',
            data: {
                columns: data as any
            }
        });
    }, []);

    return <div id="ranking-chart">
        <h3>{t('page.end.rankingChart-title')}</h3>
        <div id="ranking-chart-chart" className="asdf-chart"/>
    </div>
}

export default RankingChart;
