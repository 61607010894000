import React, {useState} from 'react';
import './DoneButton.css';
import axios from "axios";
import {useTranslation} from "react-i18next";

type DoneButtonProps = { sessionId: string, finishClicked: Function }
const DoneButton = (props: DoneButtonProps) => {
// TODO grow button when waiting too long
    const {t} = useTranslation();
    const [doneSent, setDoneSent] = useState(false);

    function roundFinished() {
        axios(`${process.env.REACT_APP_BACKEND_URL}/game/round-finished`,
            {
                method: 'put',
                data: {
                    session: props.sessionId
                }
            }
        ).then(() => {
            setDoneSent(true);
            props.finishClicked();
        })
    }

    return <div className="done-button">
        <button disabled={doneSent}
                onClick={roundFinished}>{doneSent ? t('page.validateCategories.waitingForOthers') : t('page.validateCategories.done')}</button>
    </div>;
}

export default DoneButton;
