import React, {useEffect} from 'react';
import {BrowserRouter as Router, Link, Route, Switch} from 'react-router-dom';
import sunIcon from "./img/icon_sun.png";
import moonIcon from "./img/icon_moon.png";
import './App.css';
import Home from "./components/home/Home";
import Game from "./components/game/Game";
import ConfigureGame from "./components/configure-game/Configure-game";
import Join from "./components/join/Join";
import About from "./components/about/About";
import {useTranslation} from "react-i18next";
import LanguageSwitch from "./components/language-switch/LanguageSwitch";
import Logo from "./components/logo/Logo";
import Transfer from "./components/game/Transfer";

function App() {
    const {t} = useTranslation();
    const [darkMode, setDarkMode] = React.useState(false);

    useEffect(() => {
        const json = localStorage.getItem("dark-hippo");
        const currentMode = JSON.parse(json!!);
        if (currentMode) {
            setDarkMode(true);
        } else {
            setDarkMode(false);
        }
    }, []);

    useEffect(() => {
        if (darkMode) {
            document.body.classList.add("dark");
        } else {
            document.body.classList.remove("dark");
        }
        const json = JSON.stringify(darkMode);
        localStorage.setItem("dark-hippo", json);
    }, [darkMode]);

    return (
        <Router>
            <div id="wrapper">
                <div id="header">
                    <div>
                        <Link to="/">
                            <Logo isDarkMode={darkMode}/>
                        </Link>
                    </div>
                    <div id="user-preferences">
                        <LanguageSwitch/>
                        <img src={darkMode ? moonIcon : sunIcon} onClick={() => setDarkMode(!darkMode)}
                             alt="toggle Darkmode" className="icon clickable"/>
                    </div>
                </div>
                <div id="navigation">
                    <Link to="/">{t('navigation.home-label')}</Link>
                    <Link to={`/${t('navigation.newGame-path')}`}>{t('navigation.newGame-label')}</Link>
                </div>
                <div id="content">
                    <Switch>
                        <Route path="/" exact>
                            <Home/>
                        </Route>
                        <Route path={`/${t('navigation.newGame-path')}`}>
                            <ConfigureGame/>
                        </Route>
                        <Route path={`/${t('navigation.join-path')}/:gameId`}>
                            <Join/>
                        </Route>
                        <Route path={`/${t('navigation.play-path')}`}>
                            <Game/>
                        </Route>
                        <Route path={`/${t('navigation.about-path')}`}>
                            <About/>
                        </Route>
                        <Route path="/transfer/:sessionId">
                            <Transfer/>
                        </Route>
                    </Switch>
                </div>
            </div>
            <div id="footer">
                <Link to={`/${t('navigation.about-path')}`}>{t('navigation.about-label')}</Link>
            </div>
        </Router>);
}

export default App;
