import React, {useState} from 'react';
import './StartButton.css';
import axios from "axios";
import {useTranslation} from "react-i18next";

type StartButtonProps = { sessionId: string, hostId: string }
const StartButton = (props: StartButtonProps) => {

    const {t} = useTranslation();
    const [gameStarted, setGameStarted] = useState(false);

    function startGame() {
        axios(`${process.env.REACT_APP_BACKEND_URL}/game/start`,
            {
                method: 'put',
                data: {
                    session: props.sessionId
                }
            }
        ).then(() => {
            setGameStarted(true);
        })
    }

    return <div id="start-button">
        {props.hostId === props.sessionId.substring(0, 8) ? <div className="start-explanation">
                <span>{t('page.lobby.startButton-text1')}</span>
                <br/>
                <br/>
                <button onClick={startGame}
                        disabled={gameStarted}>{gameStarted ? t('page.lobby.startButton-started') : t('page.lobby.startButton-start')}</button>
            </div> :
            <div className="start-explanation">
                <span>{t('page.lobby.startButton-text2')}</span>
            </div>}
    </div>;
}

export default StartButton;
