import React from 'react';
import '../common/Game.css';
import './EndPage.css';
import {GameState} from "../../entity/GameState";
import End from "./End";
import PlayerList from "../player-list/PlayerList";
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";

type EndPageProps = { gameState: GameState, timeOffset: number, sessionId: string }
const EndPage = (props: EndPageProps) => {

    const {t} = useTranslation();

    return <div id="game">
        <div id="play">
            <End categories={props.gameState.game.categories} playerNames={props.gameState.players.map(p => p.name)}
                 endExtra={props.gameState.extra}/>
        </div>
        <div id="info-bar">
            <div id="info-wrapper">
                <div className="game-over">{t('page.end.gameOver')}</div>
                <PlayerList players={props.gameState.players} readyPlayers={props.gameState.extra.readyPlayers}
                            currentStep={props.gameState.step} timeOffset={props.timeOffset}
                            sessionId={props.sessionId}/>
                <div className="again-button"><Link to={`/${t('navigation.newGame-path')}`}
                                                    className="button-link">{t('page.end.again')}</Link></div>
            </div>
        </div>
    </div>;
}

export default EndPage;
