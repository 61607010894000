import React, {useEffect} from 'react';
import {useHistory, useParams} from "react-router-dom";
import {setCookie} from "../../utils/CookieUtils";
import {useTranslation} from "react-i18next";

const Transfer = () => {

    const reactHistory = useHistory();
    const {t} = useTranslation();

    const {sessionId}: any = useParams();

    useEffect(() => {
        if (sessionId && sessionId !== '') {
            setCookie(sessionId)
            reactHistory.replace(`/${t('navigation.play-path')}`);
        } else {
            reactHistory.replace('/');
        }
    });

    return <div>Session transfer failed!</div>
};

export default Transfer;
