import React, {FormEvent, useEffect, useState} from 'react';
import {useHistory, useParams} from "react-router-dom";
import axios from "axios";
import './Join.css';
import {useTranslation} from "react-i18next";
import {getSessionIdFromCookie, setCookie} from "../../utils/CookieUtils";

const Join = () => {

    const {t} = useTranslation();
    const [playerName, setPlayerName] = useState('');
    const {gameId}: any = useParams();
    const history = useHistory();

    useEffect(() => {
        checkExistingCookie();
    }, []);

    function checkExistingCookie() {
        const sessionId = getSessionIdFromCookie();
        if (sessionId) {
            axios(`${process.env.REACT_APP_BACKEND_URL}/player/is-session-active/${sessionId}/${gameId}`,
                {
                    method: 'get'
                }
            ).then(() => {
                history.push(`/${t('navigation.play-path')}`);
            }).catch(e => console.log(e));
        }
    }

    function join(event: FormEvent) {
        event.preventDefault();
        event.stopPropagation();
        axios(`${process.env.REACT_APP_BACKEND_URL}/player`,
            {
                method: 'post',
                data: {
                    name: playerName,
                    gameId: gameId
                }
            }
        ).then(response => {
            setCookie(response.data);
            history.push(t(`/${t('navigation.play-path')}`));
        })
    }

    function updateInputValue(evt: React.ChangeEvent<HTMLInputElement>) {
        setPlayerName(evt.target.value);
    }

    return <div id="join">
        <h2>{t('page.join.title')}</h2>
        <br/>
        <div>
            <form onSubmit={event => join(event)}>
                <label htmlFor="player-name"><b>{t('page.join.name-label')}</b></label><br/>
                <input autoComplete="off" onChange={evt => updateInputValue(evt)} autoFocus id="player-name" type="text"
                       maxLength={30}/>
                <button type="submit" disabled={playerName.length === 0}>{t('page.join.name-button')}</button>
            </form>
        </div>
    </div>
};

export default Join;
