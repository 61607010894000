import React, {useEffect, useState} from 'react';
import {GameState, Step} from "../../entity/GameState";
import {useHistory} from "react-router-dom";
import axios from "axios";
import {calculateDistanceInSecs} from "../../utils/Calculator";
import ValidateCategoriesPage from "../validate-categories/ValidateCategoriesPage";
import LobbyPage from "../lobby/LobbyPage";
import StartPage from "../start/StartPage";
import FillCategoriesPage from "../fill-categories/FillCategoriesPage";
import WaitForAnswersPage from "../wait-for-answers/WaitForAnswersPage";
import EndPage from "../end/EndPage";
import {getSessionIdFromCookie} from "../../utils/CookieUtils";

const Game = () => {

    const reactHistory = useHistory();
    let timeOffset = 0;
    let lastPoll = 0;

    const [sessionId, setSessionId] = useState<string>('');
    const [gameState, setGameState] = useState<GameState>();

    useEffect(() => {
        const sessionId = getSessionIdFromCookie();
        if (!sessionId || sessionId === '') {
            reactHistory.replace('/');
        }
        setSessionId(sessionId);
    }, []);

    useEffect(() => {
        if (sessionId !== '') {
            syncTime();
        }
    }, [sessionId]);

    const syncTime = () => {
        fetch(`${process.env.REACT_APP_BACKEND_URL}/game/sync`)
            .then(resp => resp.text())
            .then(serverTime => {
                timeOffset = (calculateDistanceInSecs(Number(serverTime)) + 1) * 1000;
                console.log("timeOffset:", timeOffset);
                pollStatus();
            })
    };

    const pollStatus = () => axios.get(`${process.env.REACT_APP_BACKEND_URL}/game/${sessionId}`)
        .then(response => {
            const gameState = response.data as GameState;
            gameState.nextPoll -= timeOffset;
            throttlePolling(gameState);
            setGameState(gameState);
        })
        .catch(e => {
            console.error(e);
            reactHistory.push('/');
        });

    const throttlePolling = (result: GameState) => {
        if (result.nextPoll - lastPoll < 1000) {
            result.nextPoll += 1500;
        }
        lastPoll = result.nextPoll;
    };

    return <main>
        {gameState?.step === Step.LOBBY ?
            <LobbyPage callback={pollStatus} timeOffset={timeOffset} sessionId={sessionId} gameState={gameState}/> : ""}
        {gameState?.step === Step.START ?
            <StartPage callback={pollStatus} gameState={gameState} timeOffset={timeOffset} sessionId={sessionId}/> : ""}
        {gameState?.step === Step.FILL_CATEGORIES ?
            <FillCategoriesPage callback={pollStatus} timeOffset={timeOffset} sessionId={sessionId}
                                gameState={gameState} syncTimeFunc={syncTime}/> : ""}
        {gameState?.step === Step.WAIT_FOR_ANSWERS ?
            <WaitForAnswersPage callback={pollStatus} timeOffset={timeOffset} sessionId={sessionId}
                                gameState={gameState}/> : ""}
        {gameState?.step === Step.VALIDATE_CATEGORIES ?
            <ValidateCategoriesPage callback={pollStatus} timeOffset={timeOffset} sessionId={sessionId}
                                    gameState={gameState}/> : ""}
        {gameState?.step === Step.END ?
            <EndPage gameState={gameState} timeOffset={timeOffset} sessionId={sessionId}/> : ""}
    </main>;
}

export default Game;
