import React from 'react';
import '../Charts.css';
import './StatisticsTable.css';
import {PlayerStats} from "../../../entity/GameState";
import "c3/c3.css";
import {useTranslation} from "react-i18next";

type StatisticsTableProps = { stats: PlayerStats[] }
const StatisticsTable = (props: StatisticsTableProps) => {

    const {t} = useTranslation();

    return <div id="statistics-table">
        <table className="stats-table">
            <thead>
            <tr>
                <td>#</td>
                <td>{t('page.end.statistics-givenVetos')}</td>
                <td>{t('page.end.statistics-receivedVetos')}</td>
                <td>{t('page.end.statistics-validAnswers')}</td>
                <td>{t('page.end.statistics-avgAnswerLength')}</td>
                <td>{t('page.end.statistics-minAnswerLength')}</td>
                <td>{t('page.end.statistics-maxAnswerLength')}</td>
            </tr>
            </thead>
            <tbody>
            {props.stats.map(s => {
                return <tr key={s.playerName}>
                    <td>{s.playerName}</td>
                    <td>{s.givenVetos}</td>
                    <td>{s.receivedVetos}</td>
                    <td>{s.validAnswers}</td>
                    <td>{s.avgAnswerLength}</td>
                    <td>{s.minAnswerLength}</td>
                    <td>{s.maxAnswerLength}</td>
                </tr>
            })}
            </tbody>
        </table>
    </div>;
}

export default StatisticsTable;
