import React, {useState} from 'react';
import './Lobby.css';
import {Game} from "../../entity/GameState";
import copyIcon from "./copy.png";
import {useTranslation} from "react-i18next";
import i18n from "i18next";

type LobbyProps = { game: Game }
const Lobby = (props: LobbyProps) => {

    const {t} = useTranslation();
    const [showClickedMessage, setShowClickedMessage] = useState<boolean>();

    const copyLink = () => {
        const copyText = document.getElementById("game-link") as HTMLInputElement;
        if (copyText) {
            copyText.select();
            copyText.setSelectionRange(0, 99999); /* For mobile devices */

            document.execCommand("copy");

            setShowClickedMessage(true);
            setTimeout(function () {
                setShowClickedMessage(false);
            }, 1500);
        }
    }

    return <div id="lobby">
        <h1>{t('page.lobby.headline')}</h1>
        <p>
            {t('page.lobby.shareLink')}:<br/>
            <input type="text"
                   value={`${i18n.language === 'de' ? process.env.REACT_APP_FRONTEND_URL_DE : process.env.REACT_APP_FRONTEND_URL_EN}/${t('navigation.join-path')}/${props.game.gameId}`}
                   id="game-link"
                   readOnly/>&nbsp;
            <img src={copyIcon} alt="Copy Link" className="icon clickable" onClick={() => copyLink()}/>
            <span className={showClickedMessage ? '' : 'copy-message'}>{t('page.lobby.linkCopied')}</span>
        </p>
        <p><b>{t('page.configureGame.rounds-label')}:</b> {props.game.rounds} {t('page.lobby.rounds')}</p>
        <p><b>{t('page.configureGame.roundDuration-label')}:</b> {props.game.roundDuration} {t('page.lobby.seconds')}
        </p>
        <p><b>{t('page.configureGame.excludedLetters-label')}:</b> {props.game.excludedLetters}</p>
        <p>
            <b>{t('page.configureGame.creativityPoints-label')}:</b> {props.game.creativityPointsPerRound > 0 ? t('page.lobby.on') : t('page.lobby.off')}
        </p>
        {props.game.creativityPointsPerRound > 0 ? <div><p>
            <b>{t('page.configureGame.creativityPointsPerRound-label')}:</b> {props.game.creativityPointsPerRound}</p>
            <p><b>{t('page.configureGame.creativityPointsValue-label')}:</b> {props.game.creativityPointValue}</p>
        </div> : ""}
        <h2>{t('page.configureGame.categories-label')}:</h2>
        <ul id="category-preview">
            {props.game.categories.map(c => {
                return <li key={c}>{c}</li>
            })}
        </ul>
    </div>;
}

export default Lobby;
