import React, {useState} from 'react';
import {useHistory} from "react-router-dom";
import './Configure-game.css';
import CreateGame from "./CreateGame";
import CategoryInput from "./category-input/Category-Input";
import RandomCategoryService from "./category-input/RandomCategoryService";
import ReactTooltip from "react-tooltip";
import {GameConfig} from "./GameConfig";
import {useTranslation} from "react-i18next";

const ConfigureGame = () => {

    const {t} = useTranslation();
    const history = useHistory();
    const [categories, setCategories] = useState<string[]>([t('page.configureGame.defaultCategory1'), t('page.configureGame.defaultCategory2'), t('page.configureGame.defaultCategory3'), t('page.configureGame.defaultCategory4'), t('page.configureGame.defaultCategory5'), t('page.configureGame.defaultCategory6'), t('page.configureGame.defaultCategory7'), '']);
    const [filledCategoryCount, setFilledCategoryCount] = useState<number>(categories.length - 1);
    const [rounds, setRounds] = useState<number>(5);
    const [roundDuration, setRoundDuration] = useState<number>(50);
    const [excludedLetters, setExcludedLetters] = useState<string>(t('page.configureGame.excludedLetters'));
    const [creativityPoints, setCreativityPoints] = useState<boolean>(false);
    const [creativityPointsPerRound, setCreativityPointsPerRound] = useState<number>(1);
    const [creativityPointsValue, setCreativityPointsValue] = useState<number>(3);
    const [backendError, setBackendError] = useState<boolean>(false);
    const randomCategoryService = new RandomCategoryService();

    const createGame = () => {
        const config = {
            rounds,
            roundDuration,
            excludedLetters: Array.from(excludedLetters),
            categories: categories.filter(c => c.trim() !== ''),
            creativityPointsPerRound: creativityPoints ? creativityPointsPerRound : 0,
            creativityPointsValue: creativityPoints ? creativityPointsValue : 0
        } as GameConfig;

        new CreateGame().create(config)
            .then(gameId => {
                history.push(`/${t('navigation.join-path')}/${gameId}`);
            }).catch(() => setBackendError(true));
    }

    const addCategory = () => {
        setCategories(categories.concat(''));
    };

    const removeCategory = () => {
        const items = categories;
        if (items.length > 1) {
            const lastIndex = items.length - 1;
            const strings = items.filter((item, index) => index !== lastIndex);
            setCategories(strings);
            setFilledCategoryCount(strings.filter(c => c !== '').length);
        }
    };

    const setCategoryAtIndex = (event: React.ChangeEvent<HTMLInputElement>) => {
        categories[Number(event.target.id)] = event.target.value;
        setCategories(categories);
        setFilledCategoryCount(categories.filter(c => c !== '').length);
    };

    const durationRecommendation = () => {
        const recommendation = Math.round(filledCategoryCount * 7 / 10 - 1) * 10;
        return recommendation === 0 ? 10 : recommendation;
    }

    return <div id="config-wrapper">
        <div className="game-form">
            <fieldset>
                <legend>{t('page.configureGame.rounds-label')}: <a data-tip data-for="rounds-tooltip">(?)</a>
                    <ReactTooltip id="rounds-tooltip" place="top" type="dark"
                                  effect="float">{t('page.configureGame.rounds-tooltip')}</ReactTooltip>
                </legend>
                <select id="rounds" defaultValue={5}
                        onChange={event => setRounds(Number(event.target.value))}>
                    <option value="1">1 {t('page.configureGame.rounds-unit-singular')}</option>
                    <option value="2">2 {t('page.configureGame.rounds-unit')}</option>
                    <option value="3">3 {t('page.configureGame.rounds-unit')}</option>
                    <option value="4">4 {t('page.configureGame.rounds-unit')}</option>
                    <option value="5">5 {t('page.configureGame.rounds-unit')}</option>
                    <option value="6">6 {t('page.configureGame.rounds-unit')}</option>
                    <option value="7">7 {t('page.configureGame.rounds-unit')}</option>
                    <option value="8">8 {t('page.configureGame.rounds-unit')}</option>
                    <option value="9">9 {t('page.configureGame.rounds-unit')}</option>
                    <option value="10">10 {t('page.configureGame.rounds-unit')}</option>
                </select>
            </fieldset>
        </div>
        <div className="game-form">
            <fieldset>
                <legend>{t('page.configureGame.excludedLetters-label')}: <a data-tip
                                                                            data-for="excluded-characters-tooltip">(?)</a>
                    <ReactTooltip id="excluded-characters-tooltip" place="top" type="dark"
                                  effect="float">{t('page.configureGame.excludedLetters-tooltip')}</ReactTooltip>
                </legend>
                <input id="excluded-letters" type="text" value={excludedLetters} maxLength={30}
                       onChange={e => setExcludedLetters(e.target.value)}/>
            </fieldset>
        </div>
        <div className="game-form">
            <fieldset>
                <legend>{t('page.configureGame.categories-label')}: <a data-tip data-for="category-tooltip">(?)</a>
                    <ReactTooltip id="category-tooltip" place="top" type="dark"
                                  effect="float">{t('page.configureGame.categories-tooltip')}</ReactTooltip>
                </legend>
                <form autoComplete="off">
                    <ol id="categories">
                        {categories.map((c, index) => {
                                return <li className="configure-game-category" key={index}>
                                    <CategoryInput index={index} initCategory={c}
                                                   randomCategoryService={randomCategoryService}
                                                   setCategoryCallback={setCategoryAtIndex}/>
                                </li>
                            }
                        )}
                    </ol>
                </form>
                <p>
                    <input onClick={addCategory} type="button" className="category-amount-button" value="+"/>
                    <input onClick={removeCategory} type="button" className="category-amount-button" value="-"/>
                </p>
            </fieldset>
        </div>
        <div className="game-form">
            <fieldset>
                <legend>{t('page.configureGame.roundDuration-label')}: <a data-tip
                                                                          data-for="round-duration-tooltip">(?)</a>
                    <ReactTooltip id="round-duration-tooltip" place="top" type="dark"
                                  effect="float">{t('page.configureGame.roundDuration-label')}</ReactTooltip>
                </legend>
                <p><b>{t('page.configureGame.roundDuration-hint')}:</b> <span
                    key={filledCategoryCount}>{t('page.configureGame.roundDuration-hint-text').replace('<placeholder1>', String(durationRecommendation())).replace('<placeholder2>', String(durationRecommendation() + 10)).replace('<placeholder3>', String(filledCategoryCount))}</span>
                </p>
                <select id="round-duration" defaultValue={roundDuration}
                        onChange={event => setRoundDuration(Number(event.target.value))}>
                    <option value="10">10 {t('page.configureGame.roundDuration-unit')}</option>
                    <option value="20">20 {t('page.configureGame.roundDuration-unit')}</option>
                    <option value="30">30 {t('page.configureGame.roundDuration-unit')}</option>
                    <option value="40">40 {t('page.configureGame.roundDuration-unit')}</option>
                    <option value="50">50 {t('page.configureGame.roundDuration-unit')}</option>
                    <option value="60">60 {t('page.configureGame.roundDuration-unit')}</option>
                    <option value="70">70 {t('page.configureGame.roundDuration-unit')}</option>
                    <option value="80">80 {t('page.configureGame.roundDuration-unit')}</option>
                    <option value="90">90 {t('page.configureGame.roundDuration-unit')}</option>
                    <option value="100">100 {t('page.configureGame.roundDuration-unit')}</option>
                    <option value="110">110 {t('page.configureGame.roundDuration-unit')}</option>
                    <option value="120">120 {t('page.configureGame.roundDuration-unit')}</option>
                    <option value="130">130 {t('page.configureGame.roundDuration-unit')}</option>
                    <option value="140">140 {t('page.configureGame.roundDuration-unit')}</option>
                    <option value="150">150 {t('page.configureGame.roundDuration-unit')}</option>
                    <option value="160">160 {t('page.configureGame.roundDuration-unit')}</option>
                    <option value="170">170 {t('page.configureGame.roundDuration-unit')}</option>
                    <option value="180">180 {t('page.configureGame.roundDuration-unit')}</option>
                </select>
            </fieldset>
        </div>
        <div className="game-form">
            <fieldset>
                <legend>{t('page.configureGame.creativityPoints-label')} <a data-tip
                                                                            data-for="creativity-points-tooltip">(?)</a>
                    <ReactTooltip id="creativity-points-tooltip" place="top" type="dark" html={true}
                                  effect="float">{t('page.configureGame.creativityPoints-tooltip')}</ReactTooltip>
                </legend>
                <p><label><input type="checkbox"
                                 onChange={() => setCreativityPoints(!creativityPoints)}/>{t('page.configureGame.creativityPoints-toggle')}
                </label></p>
                {creativityPoints && <div>
                    <p>{t('page.configureGame.creativityPointsPerRound-label')}:&nbsp;
                        <select defaultValue={creativityPointsPerRound}
                                onChange={event => setCreativityPointsPerRound(Number(event.target.value))}>
                            <option>1</option>
                            <option>2</option>
                            <option>3</option>
                            <option>4</option>
                            <option>5</option>
                            <option>6</option>
                            <option>7</option>
                            <option>8</option>
                            <option>9</option>
                            <option>10</option>
                            <option>20</option>
                            <option>30</option>
                        </select>
                    </p>
                    <p>{t('page.configureGame.creativityPointsValue-label')}:&nbsp;
                        <select defaultValue={creativityPointsValue}
                                onChange={event => setCreativityPointsValue(Number(event.target.value))}>
                            <option>1</option>
                            <option>2</option>
                            <option>3</option>
                            <option>4</option>
                            <option>5</option>
                            <option>6</option>
                            <option>7</option>
                            <option>8</option>
                            <option>9</option>
                            <option>10</option>
                            <option>20</option>
                            <option>30</option>
                            <option>40</option>
                            <option>50</option>
                            <option>100</option>
                        </select>
                    </p>
                </div>}
            </fieldset>
        </div>
        <div className="game-form">
            <fieldset>
                <legend>{t('page.configureGame.gameReady-label')} <a data-tip data-for="ready-tooltip">(?)</a>
                    <ReactTooltip id="ready-tooltip" place="top" type="dark"
                                  effect="float">{t('page.configureGame.gameReady-tooltip')}</ReactTooltip>
                </legend>
                {backendError &&
                <p className="error">{t('page.configureGame.error')}</p>}
                <button onClick={() => createGame()}>{t('page.configureGame.createGame-button')}</button>
            </fieldset>
        </div>
    </div>;
}

export default ConfigureGame;