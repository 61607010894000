import axios from "axios";

class RandomCategoryService {

    getRandom = (language: string) => {
        return axios.get(`api/category-${language}.php`)
            .then(result => {
                return result.data;
            });
    };
}

export default RandomCategoryService;
