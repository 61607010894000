import React from 'react';
import './WaitForAnswers.css';
import {useTranslation} from "react-i18next";

const WaitForAnswers = () => {
    const {t} = useTranslation();

    function getGameQuote() {
        const items = [
            {quote: t('page.waitForAnswers.quote1'), source: t('page.waitForAnswers.source1')},
            {quote: t('page.waitForAnswers.quote2'), source: t('page.waitForAnswers.source2')},
            {quote: t('page.waitForAnswers.quote3'), source: t('page.waitForAnswers.source3')},
            {quote: t('page.waitForAnswers.quote4'), source: t('page.waitForAnswers.source4')},
            {quote: t('page.waitForAnswers.quote5'), source: t('page.waitForAnswers.source5')},
        ];
        return items[Math.floor(Math.random() * items.length)];
    }

    const phrase = getGameQuote();

    return <div id="wait-for-answers">
        <p className="smart-phrase">{phrase.quote}</p>
        <p>{t('page.waitForAnswers.quote')}: <span className="quote">{phrase.source}</span></p>
    </div>;
}

export default WaitForAnswers;
